.custom-form-group-s1 {
  text-align: left;

  label, legend {
    padding-left: 15px;
    padding-bottom: 4px;
    color: $colorN1;
    font-size: 1.10rem;
    font-weight: 400;
  }

  >div {
    .form-control, .custom-file-input {
      height: auto;
      padding: 0.60rem 1.4rem 0.65rem 1.4rem;
      color: $colorN1;
      font-size: 1.1rem;
      font-weight: 500;
      border-color: $colorN1;
      border-radius: 10px;
      background-color: transparent;
    }

    .custom-file-label {
      border-radius: 10px;
    }

    .form-control-sm {
      padding: 0.45rem 1.2rem 0.5rem 1.2rem;
      font-size: 1.0rem;
    }

    textarea {
      resize: none;
    }
  }
}
