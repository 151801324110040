.swiper-s1 {
  .swiper-button-prev, .swiper-button-next {
    width: 50px;
    height: 50px;
    border: 2px solid transparent;
    border-radius: 50%;
    background-color: transparent;
    @include transition(150ms);
    opacity: 1;

    &:after {
      font-family: "Font Awesome 5 Pro";
      font-weight: 500;
      font-size: 1.4rem;
    }
  }

  .swiper-button-prev {
    left: 20px;
    padding-right: 2px;

    &:after {
      content: "\f053";
    }
  }

  .swiper-button-next {
    right: 20px;
    padding-left: 3px;

    &:after {
      content: "\f054";
    }
  }

  @media screen and (max-width: 767px) {
    .swiper-button-prev, .swiper-button-next {
      width: 40px;
      height: 40px;
      border-width: 1px;

      &:after {
        font-size: 1.15rem;
      }
    }

    .swiper-button-prev {
      left: 14px;
    }
    .swiper-button-next {
      right: 14px;
    }
  }

  &.primary {
    .swiper-button-prev, .swiper-button-next {
      color: $cprimary;
      border-color: $cprimary;
    }
  }
  &.secondary {
    .swiper-button-prev, .swiper-button-next {
      color: $csecondary;
      border-color: $csecondary;
    }
  }
}

.swiper-s2 {
  .swiper-button-prev, .swiper-button-next {
    margin-top: -51px;
    width: 25px;
    height: 50px;
    border: 0;
    border-radius: 5px;
    background-color: $csecondary;
    @include transition(150ms);
    opacity: 0.25;

    &:after {
      color: #ffffff;
      font-family: "Font Awesome 5 Pro";
      font-weight: 500;
      font-size: 1.2rem;
    }

    &:hover {
      opacity: 1;
    }
  }

  .swiper-button-prev {
    left: 20px;
    padding-right: 2px;

    &:after {
      content: "\f053";
    }
  }

  .swiper-button-next {
    right: 20px;
    padding-left: 3px;

    &:after {
      content: "\f054";
    }
  }

  .swiper-button-disabled {
    opacity: 0 !important;
  }

  @media screen and (max-width: 767px) {
    .swiper-button-prev, .swiper-button-next {
      width: 40px;
      height: 40px;
      border-width: 1px;

      &:after {
        font-size: 1.15rem;
      }
    }

    .swiper-button-prev {
      left: 14px;
    }
    .swiper-button-next {
      right: 14px;
    }
  }

  &.primary {
    .swiper-button-prev, .swiper-button-next {
      color: $cprimary;
      border-color: $cprimary;
    }
  }
  &.secondary {
    .swiper-button-prev, .swiper-button-next {
      color: $csecondary;
      border-color: $csecondary;
    }
  }
}
