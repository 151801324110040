#about-us-page {

	.main-section {
		position: relative;
    background-color: #ffffff;

		.bg {
			position: absolute;
			width: 57%;
			height: 100%;
			background-position: right center;
			background-repeat: no-repeat;
			background-size: cover;
			background-image: url('../images/pages/about-us/bg.jpg');
			z-index: 1;
		}

		.container {
			position: relative;
			z-index: 10;

			>.row {
				align-items: center;
				min-height: 80vh;
			}

			.col-contant {
				padding-top: 20px;
				padding-bottom: 20px;
				text-align: justify;

				.title {
					margin-bottom: 5px;
					color: $colorN1;
					font-size: 2.0rem;
					font-weight: 600;
					font-family: "Libre Bodoni", serif;
					text-align: center;
					text-transform: uppercase;
				}

				p {
					font-size: 0.95rem;
					line-height: 1.4;
				}

				.col-info{
					.subtitle {
						margin-bottom: 2px;
						font-family: "Libre Bodoni", serif;
						font-size: 1.35rem;
						font-weight: 600;
					}

					.brand {
						display: inline-block;
						min-width: 180px;
						font-size: 1.05rem;
						font-weight: 500;
					}

					.price {
						display: inline-block;
						color: #696d72;
						font-size: 1.1rem;
						font-weight: 600;
					}

					.p-lg {
						font-size: 1.10rem;
  					font-weight: 500;
					}
				}
			}
		}

		@media screen and (max-width: 991px) {
			&:before {
				content: " ";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(255,255,255,0.85);
				z-index: 3;
			}

			.bg {
				width: 86%;
			}

			.container {
				.col-contant {
					text-align: center;
				}
			}
		}
		@media screen and (max-width: 575px) {
			.container {
				.col-contant {
					.title {
						font-size: 2.2rem;
					}

					p {
						font-size: 0.90rem;
					}
				}
			}
		}
	}

}
