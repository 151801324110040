.title-s1 {
  color: $csecondary2;
  font-size: 1.9rem;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;

  @media screen and (max-width: 575px) {
    font-size: 1.45rem;
  }
}
