.modal-search-e{
  .modal-content{
    border: 0;

    .modal-header{
      position: relative;
      padding: 0;
      border: 0;
      height: 0;
      z-index: 5;

      .close{
        position: absolute;
        padding: 0.95rem 1rem 0.7rem 1rem;
        top: 10px;
        right: 16px;
        font-size: 30px;
        font-weight: 600;
      }
    }

    .modal-body{
      padding: 0;

      form{
        position: relative;
        width: 100%;

        .form-group{
          margin: 0 !important;

          .form-control{
            padding-left: 78px;
            padding-right: 38px;
          }
        }

        .btn-search{
          position: absolute;
          top: 0;
          left: 0;
          width: 56px;
          height: 100%;
          color: #ffffff;
          font-size: 18px;
          border: 0 !important;
          background-color: $csecondary;
          z-index: 10;

          &:hover, &:focus, &:active{
            background-color: $csecondary2 !important;
          }
        }
      }
    }
  }
}

.modal-promo {
  .modal-content {
    background-color: $csecondary;

    .modal-header {
      position: relative;
      padding: 0;
      border: 0;
      z-index: 5;

      .close {
        position: absolute;
        padding: 2px 0 0 0;
        margin: 0;
        top: 10px;
        right: 10px;
        width: 44px;
        height: 44px;
        color: $csecondary;
        font-size: 2.2rem;
        font-weight: 700;
        opacity: 1;
        text-shadow: none;
        border-radius: 50%;
        box-shadow: 0 0 4px rgba(0,0,0,0.7);
        background-color: $colorN3;
        @include flex-center-xy();
        @include transition(150ms);
        z-index: 40;

        &:hover {
          box-shadow: 0 0 11px rgba(0, 0, 0, 0.75) !important;
        }
      }
    }

    .modal-body {
      padding: 0;
      color: #ffffff;
      text-align: center;

      .img-promo {
        width: 100%;
      }

      .box-text {
        padding: 18px 20px;

        .ul-icon {
          padding-left: 15px;

          li {
            position: relative;

            .icon {
              position: absolute;
              top: 7px;
              left: -17px;
              font-size: 0.8rem;
            }
          }
        }
      }

      ._btn {
        padding: 11px 28px 9px 28px;
        color: $csecondary !important;
        font-size: 1.15rem;
        font-weight: 700;
        letter-spacing: 3px;
        border-radius: 25px;
        background: $colorN3;

        &:hover {
          background: #d9a18a;
        }
      }
    }
  }
}
