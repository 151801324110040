// Fonts
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Bodoni:ital,wght@0,400..700;1,400..700&display=swap');

.f-w-200{
  font-weight: 200 !important;
}
.f-w-300{
  font-weight: 300 !important;
}
.f-w-400{
  font-weight: 400 !important;
}
.f-w-500{
  font-weight: 500 !important;
}
.f-w-600{
  font-weight: 600 !important;
}
.f-w-700{
  font-weight: 700 !important;
}
.f-w-800{
  font-weight: 800 !important;
}
.f-f-lbodoni{
  font-family: "Libre Bodoni", serif !important;
}
