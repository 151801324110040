#user-access-page{
  min-height: 77vh;
  // font-family: "Raleway", sans-serif;
  background-color: #ebecf0;
  // background-image: url('../images/pages/user/x.jpg');

  >.container{

    .row{
      padding: 115px 0 100px 0;

      .form-container{
        display: inline-block;
        width: 500px;
        background-color: #fff;

        .box-color{
          position: relative;
          display: inline-block;
          width: 100%;
          height: 75px;
          background-color: #fff;

          i{
            content: " ";
            position: absolute;
            margin-left: -70px;
            top: -80px;
            left: 50%;
            width: 140px;
            height: 140px;
            font-style: normal;
            background-color: $colorN1;
            border-radius: 50%;

            u{
              position: absolute;
              left: 0;
              top: 50%;
              margin-top: -40px;
              width: 100%;
              // content: "\f007";
              text-align: center;
              color: #fff;
              // font-family: "Font Awesome 5 Free";
              font-size: 69px;
              // font-weight: 900;
              line-height: 1;
              text-decoration: none;
            }
          }
        }

        .form{
          color: #6b6b6b;
          font-size: 0.95rem;
          padding: 0 25px 20px 25px;

          h1{
            color: #b6b7b7;
            text-align: center;
            font-weight: 200;
            line-height: 0.9;

            small{
              font-size: 1.7rem;
              font-weight: 300;
            }
          }

          a{
            color: $colorN1;
          }

          .form-group{
            margin-bottom: 1.35rem;

            .form-control{
              color: #76787b;
              font-weight: 400;
            }
          }

          .form-errors{
            line-height: 1.3;

            h6{
              font-size: 0.85rem;
              font-weight: 400;
            }

            ul{
              padding-left: 15px;
              li{}
            }
          }

          .btn-blue{
            min-width: 150px;
            border-color: $colorN1;
            background-color: $colorN1;
            font-weight: 300;
          }
        }
      }
    }
  }
}
