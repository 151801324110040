#home-page{

	.banner-section {
		position: relative;
		width: 100%;

		.swiper {
			.swiper-button-prev, .swiper-button-next {
		    width: 50px;
		    height: 50px;
		    // border: 2px solid transparent;
				color: #ffffff;
				border: 2px solid #ffffff;
		    border-radius: 50%;
		    background-color: transparent;
		    @include transition(150ms);
		    opacity: 1;

		    &:after {
		      font-family: "Font Awesome 5 Pro";
		      font-weight: 300;
		      font-size: 1.7rem;
		    }

				&:hover {
					opacity: 0.70;
				}
		  }

			.swiper-button-disabled {
				pointer-events: all !important;
			}

		  .swiper-button-prev {
		    left: 20px;
		    padding-right: 2px;

		    &:after {
		      content: "\f053";
		    }
		  }

		  .swiper-button-next {
		    right: 20px;
		    padding-left: 3px;

		    &:after {
		      content: "\f054";
		    }
		  }

		  @media screen and (max-width: 767px) {
		    .swiper-button-prev, .swiper-button-next {
		      width: 32px;
		      height: 50px;
					margin-top: - 35px;
					border-radius: 0;
		      border-width: 1px;

		      &:after {
		        font-size: 1.3rem;
						font-weight: 700;
		      }
		    }

		    .swiper-button-prev {
		      left: 0;
		    }
		    .swiper-button-next {
		      right: 0;
		    }
		  }

			.swiper-slide {
				background-color: #1e1d1d;

				.box {
					display: block;
					@include transition(250ms);

					img {
						width: 100%;
					}
				}

				a.box {
					cursor: pointer;
				}
			}
		}

		.desktop {}
		.mobile {
			display: none;
		}

		@media screen and (max-width: 991px) {
			.desktop {
				display: none;
			}
			.mobile {
				display: block;

				.swiper-button-prev, .swiper-button-next {
					display: none !important;
				}
			}
		}
	}

	.multi-banners-section {
		padding: 0;

		.col-banner {
			a {
				position: relative;
				display: block;
				overflow: hidden;

				img {
					width: 100%;
					@include transition(650ms);
					@include cp-property(transform, scale(1.00));
				}

				&:hover {
					img {
						@include cp-property(transform, scale(1.010));
					}
				}
			}
		}
	}

	.products-section {
		position: relative;
		padding-top: 52px;
		padding-bottom: 52px;

		.box-products {
			position: relative;

			.swiper {
				.swiper-slide {
					padding-bottom: 10px;
				}

				.swiper-pagination {
					.swiper-pagination-bullet {
						width: 13px;
						height: 13px;
						border-radius: 50%;
						background-color: $colorN1;
					}

					.swiper-pagination-bullet-active-prev, .swiper-pagination-bullet-active-next {
						transform: scale(0.78);
					}
					.swiper-pagination-bullet-active-prev-prev, .swiper-pagination-bullet-active-next-next {
						transform: scale(0.45);
					}
				}
			}
			.swiper-s1 {
				.swiper-pagination {
					.swiper-pagination-bullet {
						background-color: $colorN1;
					}
				}
			}
			.swiper-s2 {
				.swiper-pagination {
					.swiper-pagination-bullet {
						background-color: $colorN3;
					}
				}
			}
		}

		@media screen and (max-width: 991px) {
			padding-top: 35px;
			padding-bottom: 35px;
		}
		@media screen and (max-width: 767px) {
			.box-products {
				.swiper {
					.swiper-pagination {
						bottom: 0;
					}
				}
			}
		}
		@media screen and (max-width: 575px) {
			padding-top: 18px;
			padding-bottom: 28px;
		}
	}

	.banner-lg-section {
		position: relative;
		padding-top: 15px;
		padding-bottom: 30px;

		&.banner-c {
			margin-top: 55px;
			padding-bottom: 20px;
		}

		.box-banner {
			display: block;

			a {
				display: inline-block;
			}

			img {
				width: 100%;
			}
		}

		@media screen and (max-width: 991px) {
			padding: 0;

			&.banner-c {
				margin-top: 25px;
				padding-bottom: 5px;
			}
		}
		@media screen and (max-width: 575px) {
			&.banner-c {
				margin-top: 12px;
				padding-bottom: 5px;
			}
		}
	}

	// == Brands ==
	.brands-section{
		.col-brands{
			margin-bottom: 15px;

			.swiper{
				.swiper-slide{
					.box-brand{
						@extend .flex-center-xy;
						flex-wrap: wrap;
						flex: 0 0 100%;
						max-width: 100%;
						height: 165px;

						.inside{
							display: inline-block;
							width: 100%;
							text-align: center;

							img{
								max-width: 90%;
								max-height: 150px;
							}
						}

						@media screen and (min-width: 992px) and (max-width: 1199px){
							height: 135px;

							.inside{
								img{
									max-width: 100%;
									max-height: 135px;
								}
							}
						}
						@media screen and (max-width: 767px){
							.inside{
								img{
									max-width: 80%;
								}
							}
						}
					}
				}
			}
		}
	}
	// == ==

	.bg-text-section {
		position: relative;
		padding: 55px 0;
		color: #ffffff;
		background: $csecondary;
		background: linear-gradient(90deg, rgba(29,61,74,1) 17%, rgba(5,10,12,1) 100%);
		background-image: url('../images/shared/bg-header.jpg');
		background-position: center;
		background-size: cover;

		>.container {
			>.row {
				align-items: center;
				justify-content: center;
			}
		}

		.col-text {
			font-family: "Libre Bodoni", serif;
			font-size: 1.40rem;
			line-height: 1.7;
		}

		.col-logo {
			text-align: center;

			img {
				max-width: 90%;
			}
		}

		@media screen and (max-width: 991px) {
			padding: 35px 0 30px 0;

			.col-logo {
				order: 0;
				margin-bottom: 18px;

				img {
					width: 250px;
				}
			}

			.col-text {
				order: 1;
				text-align: center;
			}
		}
		@media screen and (max-width: 575px) {
			padding: 40px 0 36px 0;

			.col-logo {
				img {
					width: 190px;
				}
			}

			.col-text {
				font-size: 1.15rem;
			}
		}
	}

	.extra-info-section {
		padding-top: 55px;
		padding-bottom: 75px;

		.col-info {
			&:last-child {
				border: 0;
			}

			h6 {
				margin-bottom: 8px;
				font-size: 1.25rem;
				font-weight: 400;
				text-align: center;
			}

			p {
				font-size: 1.05rem;
				line-height: 1.6;
				text-align: justify;
			}
		}

		@media screen and (min-width: 992px) {
			.col-info {
				padding: 10px 70px;
				border-right: 1px solid #000000;
			}

			@media screen and (max-width: 1399px) {
				.col-info {
					padding: 10px 50px;

					h6 {
						font-size: 1.25rem;
					}
				}
			}
		}
		@media screen and (max-width: 991px) {
			padding-top: 10px;
			padding-bottom: 20px;

			.col-info {
				padding-top: 20px;
				padding-bottom: 20px;
				border-bottom: 1px solid #000000;
			}
		}
	}

}
