.btn-s1 {
  padding: 10px 15px;
  color: #ffffff !important;
  font-size: 0.95rem;
  font-weight: 400;
  border-radius: 25px;

  &.br-10 {
    border-radius: 10px !important;
  }

  &.primary {
    border-color: $colorN1;
    background-color: $colorN1;

    &:hover, &:focus, &:active {
      border-color: $colorN1-h !important;
      background-color: $colorN1-h !important;
    }
  }
  &.gold {
    border-color: $csecondary;
    background-color: $csecondary;

    &:hover, &:focus, &:active {
      border-color: $csecondary2 !important;
      background-color: $csecondary2 !important;
    }
  }

  &.outline-primary {
    color: $colorN1 !important;
    border-color: $colorN1;
    background-color: transparent;

    &:hover, &:focus, &:active {
      color: #ffffff !important;
      border-color: $colorN1-h;
      background-color: $colorN1-h;
    }
  }

  &.secondary {
    border-color: $csecondary;
    background-color: $csecondary;

    &:hover, &:focus, &:active {
      border-color: $csecondary2 !important;
      background-color: $csecondary2 !important;
    }
  }

  &.outline-gray {
    color: #555 !important;
    border-color: #555;
    background-color: transparent;

    &:hover, &:focus, &:active {
      color: #ffffff !important;
      border-color: #555;
      background-color: #555;
    }
  }
}
