#header{
	position: relative;
	z-index: 60;

	@media screen and (max-width: 991px) {
		height: 65px;

		.header-content {
			position: fixed;
			z-index: 99;
		}
	}

	// == Payments ==
	.header-content{
		// position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		box-shadow: 0 0px 4px rgba(0,0,0,0.4);
	}
	// == ==

	// == Header top ==
	.header-top{
		position: relative;
		padding: 8px 5px;
		width: 100%;
		color: $colorN1;
		line-height: 1.2;
		font-family: "Barlow", sans-serif;
		text-align: center;
		text-transform: uppercase;
		border-bottom: 1px solid rgba(255,255,255,0.08);
		background-color: #ffffff;

		.txt{
			display: inline-block;
			font-size: 0.9rem;
			font-style: italic;
			font-weight: 500;
			line-height: 1.15;
		}

		.ic-flag, .ic-world {
			position: relative;
			margin: 0 3px;
		}

		.ic-flag {
  		top: -2px;
			max-height: 14px;
		}

		.ic-world {
			top: -2px;
			max-height: 16px;
		}

		.imgs{
			display: inline-block;
			margin: 0 15px;

			img{
				max-height: 22px;
				margin: 0 6px;
			}
		}

		.b-1, .b-2{
			display: inline-block;
		}

		@media screen and (max-width: 1199px){
			.b-1, .b-2{
				display: block;
			}

			.b-1{
				.imgs{
					display: block;
				}
			}

			.imgs{
				img{
					height: 17px;
				}
			}
		}
		@media screen and (max-width: 991px){
			display: none;

			.txt{
				font-size: 0.85rem;
			}
			.imgs{
				margin-top: 7px;
				margin-bottom: 7px;
			}
		}
		@media screen and (max-width: 575px){
			display: none;
		}
	}
	// == ==

	// == Header menu ==
	.header-menu {
		.navbar {
			position: relative;
			background: rgb(29,61,74);
			background: linear-gradient(90deg, rgba(29,61,74,1) 17%, rgba(5,10,12,1) 100%);
			background-image: url('../images/shared/bg-header.jpg');
			background-position: center;
			background-size: cover;

			.box-logo {
				position: relative;

				.box-extra {
					position: absolute;
					display: flex;
				  flex-wrap: wrap;
				  align-items: center;
					top: 0;
					right: 0;
				  height: 100%;

					.btn-search {
						display: inline-block;
						padding: 13px 14px 10px 14px;
						color: #ffffff;
						cursor: pointer;
						font-size: 1.3rem;
						border: 1px solid #19202d;
						border-radius: 5px;
						// background: rgba(14, 0, 66, 0.5);
						background: #263144;

						&:hover {
							opacity: 0.85;
						}
					}
				}
			}

			.navbar-brand {
				img {
					max-height: 78px;
				}
			}

			.navbar-toggler {
				padding: 0.25rem 0.95rem;
				color: #ffffff;
				font-size: 2.0rem;
				border-radius: 3px;
				background-color: transparent;
			}

			.navbar-nav {
				.simple-item {
					.nav-link {
						color: #ffffff;
						cursor: pointer;
						font-family: "Libre Bodoni", serif;
						font-size: 1.10rem;
						font-weight: 400;
						@include transition(150ms);

						&:hover {
							color: $colorN3;
						}
					}

					.dropdown-menu {
						li {
							.dropdown-item {
								font-family: "Libre Bodoni", serif;
								font-weight: 600;

								&:hover, &:focus, &:active {
									color: #fff !important;
									background: $cprimary !important;
								}
							}
						}
					}
				}

				.b-nav-dropdown, .dropdown-toggle, .dropdown-menu, .dropdown-item {
					box-shadow: none !important;
					outline: none !important;
				}

				.cart-item, .user-item {
					.nav-link {
						color: #ffffff;
						font-family: "Libre Bodoni", serif;
						font-size: 1.30rem;
						font-weight: 500;
						@include transition(150ms);

						.icon {
							font-size: 1.45rem;
						}

						.label {
							display: none;
						}

						&:hover {
							color: $colorN3;

							.num {
								background-color: $colorN3;
							}
						}
					}
				}

				.cart-item {
					.nav-link {
						position: relative;

						>div {
							position: relative;
							display: inline-block;
						}

						.num {
							position: absolute;
							top: -9px;
							right: -11px;
							width: 22px;
							height: 22px;
							color: $colorN1;
							line-height: 1;
							font-size: 0.75rem;
							font-style: normal;
							font-weight: 500;
							border-radius: 50%;
							background: #ffffff;
							@include flex-center-xy();
							@include transition(150ms);
						}
					}
				}

				.b-nav-dropdown {
					.dropdown-menu {
						border: 0;
						background-color: transparent !important;

						li {
							.dropdown-item {
								padding-left: 10px;
								font-weight: 400;
								color: $colorN3 !important;
							}
						}
					}
				}

				.user-item {
					&.dropdown {
						.nav-link {
							&:after {
								display: none;
							}
						}

						.dropdown-menu {
							padding: 0.35rem 0;
							border-radius: 0;
							border: 0;
							background-color: $colorN1;

							li {
								.dropdown-item {
									padding: 0.2rem 1.2rem;
									color: #ffffff;
									font-size: 0.93rem;

									&:hover {
										background-color: $colorN1-h;
									}
								}
							}
						}
					}
				}
			}
		}

		@media screen and (min-width: 992px) {
			.navbar {
				padding-bottom: 22px;

				>.container {
					flex-wrap: wrap;
				}

				.box-logo {
					position: relative;
					flex: 0 0 100%;
					max-width: 100%;
					padding-top: 16px;
					padding-bottom: 20px;
					margin-bottom: 18px;
					border-bottom: 5px solid $colorN3;
					text-align: center;

					&:before, &:after {
						content: " ";
						position: absolute;
						bottom: -6px;
						width: 110px;
						height: 7px;
						z-index: 5;
					}

					&:before {
						left: 0;
						background: linear-gradient(90deg,#1c2739 9%,rgba(29,61,74,0));
					}

					&:after {
						right: 0;
						background: linear-gradient(90deg,rgba(8,16,19,0) 11%,#485a7b);
					}
				}

				.navbar-nav {
					.nav-item {
						position: relative;
						display: flex;
						align-items: center;
					}

					.simple-item {
						margin-left: 1.0rem;
						margin-right: 1.0rem;
						line-height: 1.2;
						text-align: center;
						text-transform: uppercase;

						&:first-child {
							margin-left: 0;
						}
						&:last-child {
							margin-right: 0;
						}
					}

					.cart-item, .user-item {
						margin-left: 0.4rem;
						margin-right: 0.4rem;

						.nav-link {
							font-size: 1.7rem !important;

							.num {
								position: absolute;
								top: -3px;
							}
						}
					}
				}
			}

			@media screen and (max-width: 1635px) {
				.navbar {
					.navbar-nav {
						.simple-item {
							margin-left: 0;
							margin-right: 0;

							.nav-link {
								font-size: 0.95rem;
							}
						}
					}
				}
			}
			@media screen and (max-width: 1299px) {
				.navbar {
					.navbar-nav {
						.simple-item {
							.nav-link {
								font-size: 0.90rem;
							}
						}
					}
				}
			}
			@media screen and (max-width: 1199px) {
				.navbar {
					.navbar-nav {
						.simple-item {
							margin-left: 0.0rem;
							margin-right: 0.0rem;

							.nav-link {
								font-size: 1.00rem;
							}
						}

						.cart-item, .user-item {
							margin-left: 0.1rem;
							margin-right: 0.1rem;

							.nav-link {
								font-size: 1.3rem !important;
							}
						}
					}
				}
			}
		}

		@media screen and (max-width: 991px) {
			.navbar {
				padding-left: 0;
				padding-right: 0;
				background: #37445a !important;
				// background: linear-gradient(90deg, #130d4e 17%, #0c0a2d 100%);

				&:before {
					content: " ";
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 68px;
					background-image: url('../images/shared/bg-header.jpg');
					background-position: center;
					background-size: cover;
				}

				>.container {
					z-index: 10;
				}

				.box-logo {
					margin: 0 auto;
					width: calc(100% - 65px);

					.box-extra {
						right: 16px;

						.btn-search {
							padding: 8px 14px 5px 14px;
							font-size: 1.20rem;
							border: 0;
						}
					}
				}

				.navbar-brand {
					padding-top: 0.15rem;
					padding-bottom: 0.15rem;
					margin-right: 0;
					margin-left: 12px;

					img {
						max-height: 46px;
					}
				}

				.navbar-toggler  {
					margin-right: 15px;
					margin-left: -15px;
				}

				#nav-collapse {
					.navbar-nav {
						.simple-item {
							text-align: center;

							.nav-link {
								padding: 0.5rem 0;
								text-transform: uppercase;
							}
						}

						.special-item {
							position: relative;

							// &:before {
							// 	content: " ";
							// 	position: absolute;
							// 	top: 0;
							// 	left: 0;
							// 	width: 100%;
							// 	height: 100%;
							// 	background: $colorN3;
							// }
							//
							// .nav-link {
							// 	position: relative;
							// 	color: $csecondary !important;
							// 	z-index: 10;
							// }
						}

						.cart-item, .user-item {
							.nav-link {
								padding: 0.4rem 0;

								.icon {
									margin-right: 2px;
									width: 26px;
									font-size: 1.25rem;
									text-align: center;
								}

								.label {
									display: inline-block;
									font-size: 1.15rem;
								}
							}
						}

						.cart-item {
							margin-top: 8px;

							.nav-link {
								.num {
									right: inherit;
									left: 12px;
								}
							}
						}
					}
				}
			}
		}
	}
	// == ==

	// == Header search movil ==
	.header-search{
		position: relative;
		width: 100%;
		color: #ffffff;
		background-color: #1e4351;

		.container{
			padding-top: 7px;
			padding-bottom: 7px;
			text-align: right;
		}

		.col-right{
			.content{
				display: inline-block;
				width: 100%;
				text-align: left;

				form{
					position: relative;
					@include placeholder(#ffffff, 400, normal, 0.95rem);

					&:before{
						content: "\f002";
						position: absolute;
						top: 9px;
						left: 16px;
						color: #ffffff;
						font-family: "Font Awesome 5 Pro";
						font-size: 1.2rem;
						font-weight: 400;
						line-height: 1;
						z-index: 10;
					}

					.form-control{
						padding-left: 48px;
						width: calc(100% - 135px);
						height: 38px;
						color: #ffffff !important;
						font-size: 0.95rem;
						border-radius: 8px 0 0 8px;
						border-color: #ffffff;
						background-color: transparent !important;
					}

					.btn-search{
						width: 135px;
						height: 38px;
						color: #1e4351;
						font-size: 0.95rem;
						font-weight: 600;
						background-color: #ffffff;
						border-color: #ffffff;
						border-radius: 0 8px 8px 0;

						&:hover{
							opacity: 0.90;
						}
					}
				}
			}
		}

		@media screen and (min-width: 992px) {
			// display: none !important;
		}
		@media screen and (max-width: 767px){
			.container{
				padding: 7px;

				>.row{
					margin-left: -7px;
					margin-right: -7px;
				}
			}

			.col-right{
				padding-left: 7px;
				padding-right: 7px;

				.content{
					width: 100%;

					form{
						.form-control{
							width: calc(100% - 112px);
						}
						.btn-search{
							width: 110px;
						}
						.btn-asesoria{
							display: none;
						}
					}
				}
			}
		}
	}
	// == ==

	// == Products menu ==
	.products-menu{
		position: fixed;
		padding-top: 15px;
		padding-bottom: 15px;
		top: 0;
		left: 0;
		width: 335px;
		height: 100%;
		color: #fff;
		font-size: 15px;
		box-shadow: 1px 0px 2px rgba(0,0,0,0.2);
		// background: $cprimary;
		background: rgb(29,61,74);
		background: linear-gradient(180deg, rgba(29,61,74,1) 0%, rgba(5,10,12,1) 100%);
		opacity: 0;
		visibility: hidden;
		z-index: 480;
		@include cp-property(transform, translateX(-380px) !important);

		a{
			color: #fff;
		}

		.menu-bg{
			position: fixed;
			top: 0;
			left: 0;
			width: 100vw;
			height: 100%;
			background-color: transparent;
			z-index: 1;
		}

		.tablist{
			position: relative;
			width: 100%;
			height: 100%;
			overflow: auto;
			overflow-x: hidden;
			z-index: 2;

			.box-title{
				padding: 8px 0px;
				margin-bottom: 6px;
				font-weight: 400;
				text-align: center;
				cursor: pointer;

				&:hover{
					opacity: 0.8;
				}

				h4{
					font-size: 1.3rem;
				}

				.icon{
					position: absolute;
					top: 13px;
					right: 24px;
					font-size: 1.2rem;
				}
			}
		}

		.card{
			border: 0;
			border-radius: 0;
			background-color: transparent;

			.card-header{
				background-color: transparent;
				border: 0;
				border-radius: 0;
				padding: 0.2rem;

				.btn-toggle{
					position: relative;
					display: block;
					padding: 0px 45px 0px 10px;
					text-align: right;
					color: #fff;
					text-transform: uppercase;
					font-size: 14px;
					font-weight: 700;
					cursor: pointer;
					@extend .t-250;

					.fake-toggle{
						position: absolute;
						right: 0;
						top: 0;
						width: 43px;
						height: 100%;
						z-index: 10;
					}

					&:after{
						content: " ";
						position: absolute;
						top: 7px;
						right: 18px;
						width: 16px;
						height: 8px;
						background-position: center;
						background-repeat: no-repeat;
						background-size: contain;
						background-image: url('../images/shared/chevron-down.svg');
						z-index: 1;
					}

					&:hover{
						opacity: 0.8;
					}
				}
				.btn-toggle-fake{
					position: relative;
					display: block;
					padding: 0px 45px 0px 10px;
					text-align: right;
					color: #fff;
					text-transform: uppercase;
					font-size: 14px;
					font-weight: 700;
					cursor: pointer;
					@extend .t-250;

					.fake-toggle{
						position: absolute;
						right: 0;
						top: 0;
						width: 43px;
						height: 100%;
						z-index: 10;
					}

					&:hover{
						opacity: 0.8;
					}
				}

				.btn-direct-link{
					position: relative;
					right: -43px;
					padding-right: 43px;
				}
			}

			.card-body{
				padding: 0.4rem 0;
				line-height: 1.3;
				background: linear-gradient(180deg, rgb(37, 68, 80) 0%, rgb(29, 61, 74) 100%);

				.ul-sub-options{
					padding-left: 0;
					margin-bottom: 0;
					list-style-type: none;

					li{
						margin-top: 5px;
						margin-bottom: 5px;
						display: inline-block;
						width: 100%;
						font-size: 14px;
						text-align: right;

						a{
							display: block;
							padding: 0px 1.45rem;

							&:hover{
								text-decoration: none;
								opacity: 0.8;
							}
						}
					}
				}
			}
		}

		&.active{
			opacity: 1;
			visibility: visible;
			@include cp-property(transform, translateX(0px) !important);
		}
	}
	// == ==

}
