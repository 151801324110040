#sell-upgrade-page{

  .main-section{
    padding-top: 35px;
		padding-bottom: 35px;
    color: #ffffff;
    background-color: $colorN3;

    .col-top{
      margin-bottom: 15px;
      color: $csecondary;
      text-align: center;

      .img-upgrade {
        width: 160px;
      }

      .title-s1{
        font-family: "Libre Bodoni", serif;
        font-size: 2.2rem;
        font-weight: 700;
        text-align: center;
      }

      .text-1 {
        font-family: "Libre Bodoni", serif;
        font-size: 1.4rem;
        font-weight: 500;
      }

      .box-steps {
        .col-line {
          margin-top: 10px;

          hr {
            border-top: 1px solid $csecondary;
          }
        }

        .col-step {
          margin-top: 10px;
          margin-bottom: 10px;
          text-align: center;

          .number {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            width: 60px;
            height: 60px;
            border: 3px solid $csecondary;
            font-size: 2.2rem;
            font-weight: 600;
            border-radius: 50%;
          }

          .text {
            margin-top: 6px;
            font-size: 0.95rem;
            font-weight: 600;
            line-height: 1.4;
          }
        }
      }
    }

    .col-info{
      text-align: right;
      line-height: 1.3;

      .title{
        font-size: 1.15rem;
        line-height: 1.1;
      }

      .subtitle{
        color: $csecondary3;
        font-size: 1.10rem;
        font-weight: 600;
        line-height: 1.1;
      }

      .btn-network{
        color: #444645;
        font-size: 1.6rem;
      }
    }

    .col-form {
      .custom-form-group-s1 {
        label, legend {
          font-weight: 500;
        }

        >div {
          .form-control {
            border-color: #ffffff;
            background-color: #ffffff;
          }
        }
      }

      .btn-s1 {
        padding: 8px 15px;
        width: 250px;
        font-size: 1.1rem;
        font-weight: 500;
        border-width: 2px;
      }
    }

    @media screen and (max-width: 991px){
      .col-top{
        margin-bottom: 0;
      }

      .col-info{
        margin-bottom: 15px;
        text-align: center;
      }
    }
    @media screen and (max-width: 991px){
      .col-form {
        .btn-s1 {
          width: 100%;
        }
      }
    }
  }

}
