#product-description-page {

  .product-info-section {
    padding-top: 15px;
    padding-bottom: 25px;

    .col-gallery {
      position: relative;
      padding-bottom: 46px;

      .box-bubbles {
        position: absolute;
        text-align: center;
        bottom: 0;
        width: 100%;
        left: 0;
        z-index: 88;

        .bubble {
          display: inline-block;
          padding: 5px 15px 2px 15px;
          min-width: 140px;
          color: #ffffff;
          font-size: 0.95rem;
          font-weight: 400;
          letter-spacing: 1px;
          text-align: center;
          text-transform: uppercase;
          border-radius: 3px;
          background-color: $colorN1;
          z-index: 10;

          &.bg-1 {
            background-color: $colorN1 !important;
          }
          &.bg-2 {
            letter-spacing: 8px;
            background-color: #979594 !important;
          }
          &.bg-3 {
            color: $csecondary;
            background-color: $cprimary !important;
          }
          &.bg-4 {
            background-color: #91a7b1 !important
          }
          &.bg-5 {
            background-color: $csecondary !important
          }
          &.bg-6 {
            min-width: 130px;
            letter-spacing: 8px;
            background-color: #495a5f !important;
          }
        }
      }

      .swiper {
        .swiper-slide {
          position: relative;
          padding-bottom: 35px;
          padding-left: 40px;
          padding-right: 40px;
          text-align: center;

          &:after {
            content: " ";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 20;
            background-position: center;
            background-repeat: repeat;
            background-image: url('../images/over.png');
            pointer-events: none;
          }

          .box-image {
            display: block;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;

            img {
              width: 100%;
            }
          }
        }

        .swiper-button-disabled {
          opacity: 0.5;
        }

        .swiper-pagination {
          .swiper-pagination-bullet {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            border: 0;
            opacity: 1 !important;
            background-color: $colorN1;
          }
        }
      }
    }

    .col-info {
      padding-bottom: 25px;

      .p-name {
        color: $colorN1;
        font-size: 1.9rem;
        font-weight: 500;
        text-transform: uppercase;
      }

      .p-label {
        margin-bottom: 2px;
        color: #181818;
        font-size: 0.95rem;
      }

      .p-price {
        margin-top: 8px;
        color: #75777a;
        font-family: "Kanit", "Raleway", sans-serif;
        font-size: 1.70rem;
        font-weight: 400;

        .discount {
          margin-right: 10px;
          color: #c7c4c4;
          text-decoration: line-through;
        }
      }

      .subtitle {
        color: $csecondary2;
        font-size: 0.95rem;
        font-weight: 700;
        line-height: 1.0;
      }

      .b-descr {
        text-align: justify;

        p, span, b, strong, h1,h2,h3,h4,h5,h6 {
          color: $csecondary !important;
          font-size: 1.0rem !important;
          font-weight: 400 !important;
          line-height: 1.45;
        }
      }

      .box-descr {
        display: block;
        line-height: 1.2;
        font-size: 0.95rem;
        text-align: justify;

        p, h6, span {
          font-size: 0.95rem !important;
        }

        .select-s1 {
          display: inline-block;
          padding: 9px 24px 8px 16px;
          width: 280px;
          height: inherit;
          color: $cprimary;
          cursor: pointer;
          font-size: 0.85rem;
          font-weight: 500;
          letter-spacing: 1px;
          text-align: center;
          border: 1px solid $cprimary;
          border-radius: 20px;
          background-color: transparent;
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%231a48a4' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e");

          option {
            color: #111111;

            &:first-child {
              color: #777777;
            }
          }
        }
      }

      .col-quantity2{
        display: flex;

        .input-q{
          padding-top: 7px;
          padding-left: 3px;
          padding-right: 3px;
          width: 45px;
          height: 34px;
          font-family: Arial, "Noto Sans", sans-serif;
          font-size: 1.0rem;
          font-weight: 600;
          text-align: center;
          border: 0;
          border-top: 1px solid $csecondary2;
          border-bottom: 1px solid $csecondary2;
          border-radius: 0;
        }

        .btn-q{
          height: 34px;
          padding-top: 6px;
          padding-bottom: 6px;
          width: 40px;
          color: $csecondary3;
          border: 1px solid $csecondary2;
          cursor: pointer;

          &:first-child{
            border-radius: 0.25rem 0 0 0.25rem;
          }
          &:last-child{
            border-radius: 0 0.25rem 0.25rem 0;
          }
        }
      }

      .btn-add {
        min-width: 280px;
        font-size: 1.05rem;
        letter-spacing: 3px;
      }

      .txt-list-info {
        padding-left: 15px;
        margin-top: 20px;
        color: $csecondary;
        list-style-type: circle;
        text-transform: uppercase;

        li {
          margin-bottom: 8px;

          p, span, b, strong, h1,h2,h3,h4,h5,h6 {
            color: $csecondary !important;
          }
        }
      }
    }

    @media screen and (max-width: 991px) {
      .col-gallery {
        .box-bubbles {
          padding-left: 15px;
          padding-right: 15px;
          text-align: left;

          .bubble {
            font-size: 0.95rem;
            min-width: inherit;
          }
        }

        .swiper {
          .swiper-slide {
            padding-bottom: 46px;
          }
        }
      }
    }
    @media screen and (max-width: 575px) {
      .col-info {
        margin-top: 16px;

        .p-name {
          font-size: 1.6rem;
        }
      }
    }
  }

  .related-products-section {
    margin-top: 30px;
    padding-bottom: 10px;

    .swiper {
      .swiper-slide {
        padding-bottom: 10px;
      }

      .swiper-pagination {
        .swiper-pagination-bullet {
          width: 10px;
          height: 10px;
          border-radius: 4px;
          background-color: $colorN1;
        }
      }
    }
  }

}
