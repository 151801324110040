.row-prd-xxl-5 {
  @media screen and (min-width: 1600px) {
    .box-product-sample-s1 {
      flex: 0 0 20%;
      max-width: 20%;
    }
  }
}

.box-product-sample-s1 {
  margin-top: 15px;
  margin-bottom: 15px;

  .box-link {
    position: relative;
    display: inline-block;
    padding: 10px;
    width: 100%;
    color: #3e3e3e;
    border: 1px solid transparent;
    // border-radius: 10px;
    // box-shadow: 0 0 5px rgba(0,0,0,0.15);
    background-color: #ffffff;
    text-decoration: none !important;

    .box-bubbles {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      text-align: center;
      z-index: 25;

      .bubble {
        display: inline-block;
        padding: 5px 7px 2px 7px;
        min-width: 60px;
        color: #ffffff;
        font-size: 0.85rem;
        font-weight: 400;
        letter-spacing: 1px;
        text-align: center;
        text-transform: uppercase;
        border-radius: 3px;
        background-color: $colorN1;
        z-index: 10;

        &.bg-1 {
          background-color: $colorN1 !important;
        }
        &.bg-2 {
          min-width: 85px;
          letter-spacing: 6px;
          background-color: #979594 !important;
        }
        &.bg-3 {
          color: $csecondary;
          background-color: $cprimary !important;
        }
        &.bg-4 {
          background-color: #91a7b1 !important
        }
        &.bg-5 {
          background-color: $csecondary !important
        }
        &.bg-6 {
          min-width: 85px;
          letter-spacing: 6px;
          background-color: #495a5f !important
        }
      }
    }

    .box-image {
      position: relative;
      display: block;
      margin-bottom: 4px;
      border-radius: 8px;
      background-size: contain !important;
      // background-color: #efefef;
      overflow: hidden;
      pointer-events: none;

      img {
        width: 100%;
        margin-bottom: 46px;
      }

      &:after {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 20;
        background-position: center;
        background-repeat: repeat;
        background-image: url('../images/over.png');
      }
    }

    .box-descr {
      display: block;
      padding: 8px 0;
      text-align: center;

      .brand {
        margin-bottom: 8px;
        color: $colorN1;
        font-family: "Libre Bodoni", serif;
        font-size: 1.05rem;
        font-weight: 600;
      }

      .name {
        margin-bottom: 8px;
        min-height: 92px;
        color: #3d3d3e;
        font-family: "Libre Bodoni", serif;
        font-size: 1.25rem;
        font-weight: 600;
        line-height: 1.15;
        text-transform: uppercase;
      }

      .descr {
        display: block;
        padding-top: 1px;
        min-height: 49px;
        color: #979595;
        font-size: 0.90rem;
        line-height: 1.1;
      }

      .price {
        margin-top: 10px;
        color: #75777a;
        font-family: "Libre Bodoni", serif;
        font-size: 1.50rem;
        font-weight: 600;
        letter-spacing: 2px;

        span {
          font-size: 1.30rem;
        }
      }

      .btn-more {
        display: inline-block;
        min-width: 180px;
        padding: 6px 15px 7px 15px;
        color: #ffffff;
        font-size: 1.00rem;
        font-weight: 400;
        text-align: center;
        border-radius: 18px;
        background-color: #1e4351;

        &.bg-gold {
          background-color: $colorN3;
        }
      }
    }
  }

  a.box-link {
    cursor: pointer;
    @include transition(250ms);

    &:hover {
      opacity: 0.85;
      // border-color: $cprimary;
    }
  }

  @media screen and (max-width: 575px) {
    margin-top: 10px;
    margin-bottom: 10px;

    .box-link {
      .box-bubbles, .box-descr {
        text-align: left;
      }

      .box-descr {
        .name {
          min-height: inherit;
        }
      }
    }
  }
}

.box-product-sample-s2 {
  margin-top: 15px;
  margin-bottom: 15px;

  .box-link {
    display: flex;
    flex-wrap: wrap;
    flex: 0 0 100%;
    max-width: 100%;
    align-items: center;
    color: #34322e;

    .col-image {
      padding: 0;
      flex: 0 0 385px;
      max-width: 385px;

      .image {
        position: relative;
        pointer-events: none;

        &:after {
          content: " ";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 20;
          background-position: center;
          background-repeat: repeat;
          background-image: url('../images/over.png');
        }
      }

      img {
        width: 100%;
      }
    }

    .col-info {
      padding-left: 20px;
      color: #34322e;

      .box-bubbles {
        position: relative;
        display: block;
        margin-bottom: 20px;
        width: 100%;
        text-align: left;
        z-index: 25;

        .bubble {
          display: inline-block;
          padding: 6px 13px 2px 13px;
          min-width: 60px;
          color: #ffffff;
          font-size: 0.90rem;
          font-weight: 400;
          letter-spacing: 1px;
          text-align: center;
          text-transform: uppercase;
          border-radius: 3px;
          background-color: $colorN1;
          z-index: 10;

          &.bg-1 {
            background-color: $colorN1 !important;
          }
          &.bg-2 {
            min-width: 105px;
            letter-spacing: 8px;
            background-color: #979594 !important;
          }
          &.bg-3 {
            color: $csecondary;
            background-color: $cprimary !important;
          }
          &.bg-4 {
            background-color: #91a7b1 !important
          }
          &.bg-5 {
            background-color: $csecondary !important
          }
        }
      }

      .brand {
        font-size: 1.15rem;
      }

      .name {
        margin-bottom: 10px;
        font-size: 1.70rem;
        font-weight: 600;
        letter-spacing: 2px;
        text-transform: uppercase;
      }

      .descr {
        font-size: 1.25rem;
      }

      .price {
        margin-top: 25px;
        color: #75777a;
        font-size: 1.80rem;
        line-height: 1.0;
        letter-spacing: 1px;
      }
    }
  }

  @media screen and (max-width: 991px) {
    .box-link {
      .col-image {
        flex: 0 0 200px;
        max-width: 200px;
      }

      .col-info {
        padding-left: 15px;

        .box-bubbles {
          margin-bottom: 10px;

          .bubble {
            font-size: 0.70rem;
          }
        }

        .brand {
          font-size: 1.00rem;
        }

        .name {
          margin-bottom: 5px;
          font-size: 1.40rem;
          font-weight: 700;
          line-height: 1.1;
        }

        .descr {
          font-size: 1.00rem;
        }

        .price {
          margin-top: 6px;
          font-size: 1.45rem;
          font-weight: 600;
        }
      }
    }
  }
  @media screen and (max-width: 575px) {
    .box-link {
      .col-image {
        flex: 0 0 110px;
        max-width: 110px;
      }

      .col-info {
        padding-left: 10px;

        .box-bubbles {
          margin-bottom: 10px;

          .bubble {
            font-size: 0.60rem;
          }
        }

        .brand {
          font-size: 0.80rem;
        }

        .name {
          margin-bottom: 3px;
          font-size: 1.15rem;
          line-height: 1.0;
        }

        .descr {
          font-size: 0.80rem;
          line-height: 1.30;
        }

        .price {
          margin-top: 8px;
          font-size: 1.20rem;
        }
      }
    }
  }
}
